import React, { Component } from 'react'
import Layout from '../components/Layout'
import OurCompanyNavigationRow from '../components/OurCompanyNavigationRow'

export default class GetHelp extends Component {
    render() {
        return (
            <Layout
                title="Get Help and Support on Frequently Asked Questions | HomeSpotter"
                description="Access our help center and contact us"
            >
                <OurCompanyNavigationRow location={this.props.location} />
                <div className="ourcompany gethelp col-12">
                    <h1>Need help?</h1>
                    <div className="gethelp content">
                        <div className="card">
                            <div className="title">
                                Boost for intelligent advertising
                            </div>

                            <div className="text">
                                Have questions regarding your existing Boost
                                service? Visit our help center below to find
                                support options.
                            </div>
                            <a
                                className="boost"
                                href="https://lonewolf.my.site.com/s/boost-resources"
                            >
                                Visit our help center
                                <span className="arrow">➜</span>
                            </a>
                        </div>

                        <div className="card">
                            <div className="title">Spacio for open houses</div>

                            <div className="text">
                                Get support for Spacio’s open house system by
                                visiting the help center below.
                            </div>
                            <a className="spacio" href="https://lonewolf.my.site.com/s/spacio-resources">
                                Visit our help center
                                <span className="arrow">➜</span>
                            </a>
                        </div>

                        <div className="card">
                            <div className="title">
                                Connect for customized apps
                            </div>

                            <div className="text">
                                Need help with your personalized app? Connect
                                with us at our help center.
                            </div>
                            <a
                                className="connect"
                                href="https://lonewolf.my.site.com/s/connect-resources"
                            >
                                Visit our help center
                                <span className="arrow">➜</span>
                            </a>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
